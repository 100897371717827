import { Link } from 'react-router-dom';

export default function ConteudoIndisponivel() {
  return (
    <div className="estado-vazio estado-vazio_altura-pequeno estado-vazio_cor-erro">
      <i className="far fa-times-circle estado-vazio__icone" />
      <h2 className="estado-vazio__titulo">
        Não foi possível carregar o conteúdo
      </h2>
      <p className="estado-vazio__texto">
        Tente novamente clicando no botão abaixo. Se o problema persistir, entre
        em contato com o suporte.
      </p>
      <Link
        className="botao botao_medio botao_cor-info botao_contorno estado-vazio__botao"
        to="."
      >
        Tentar novamente
      </Link>
    </div>
  );
}
