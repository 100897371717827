/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfigItem } from 'models';

import { Permissoes } from 'enums';
import { ConsumidorProvider, ConfiguracoesAgendaProvider } from 'hooks';
import { FilaAtendimentoProvider } from 'pages/FilaAtendimento/context/FilaAtendimentoContext';
import { AtendimentoProvedor } from 'pages/Atendimento/contexto/AtendimentoContexto';

export const routesConfig: RouteConfigItem[] = [
  {
    id: '@procon-ms:e-procon',
    path: '/',
    elementPath: 'EProcon',
    isPrivate: false,
    redirectPath: '/app/aplicativos',
  },
  {
    id: '@procon-ms:app',
    path: '/app',
    isPrivate: true,
    children: [
      {
        id: '@procon-ms:configuracoes-de-agenda',
        path: '/configuracoes-de-agenda',
        elementPath: 'ConfiguracoesDeAgenda',
        isPrivate: true,
        context: ConfiguracoesAgendaProvider,
        permissions: [
          Permissoes.GESTOR_DE_AUDIENCIA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
      },
      {
        id: '@procon-ms:aplicativos',
        path: '/aplicativos',
        elementPath: 'Aplicativos',
        isPrivate: true,
      },
      {
        id: '@procon-ms:fila-atendimento',
        path: '/fila-atendimento',
        elementPath: 'FilaAtendimento',
        isPrivate: true,
        context: FilaAtendimentoProvider,
        permissions: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
      },
      {
        id: '@procon-ms:consulta-atendimento',
        path: '/consulta-atendimento',
        elementPath: 'ConsultaAtendimento',
        isPrivate: true,
        permissions: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
          Permissoes.GESTOR_DE_AUDIENCIA,
        ],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
      },
      {
        id: '@procon-ms:consulta-audiencia',
        path: '/consulta-audiencia',
        elementPath: 'ConsultaAudiencia',
        isPrivate: true,
        permissions: [
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
          Permissoes.GESTOR_DE_AUDIENCIA,
        ],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
      },
      {
        id: '@procon-ms:fornecedores',
        path: '/fornecedores',
        elementPath: 'Fornecedores',
        isPrivate: true,
        permissions: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
          Permissoes.CONCILIADOR,
          Permissoes.GESTOR_DE_AUDIENCIA,
        ],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
      },
      {
        id: '@procon-ms:minhas-audiencias',
        path: '/minhas-audiencias',
        elementPath: 'MinhasAudiencias',
        isPrivate: true,
        permissions: [Permissoes.CONCILIADOR],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
      },
      {
        id: '@procon-ms:agenda-geral',
        path: '/agenda-geral',
        elementPath: 'AgendaGeral',
        isPrivate: true,
        permissions: [
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
          Permissoes.GESTOR_DE_AUDIENCIA,
        ],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
      },
      {
        id: '@procon-ms:pre-atendimento',
        path: '/pre-atendimento',
        elementPath: 'PreAtendimento',
        isPrivate: true,
        context: ConsumidorProvider,
        permissions: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
        children: [
          {
            id: '@procon-ms:resumo-pre-atendimento',
            path: '/resumo',
            elementPath: 'ResumoPreAtendimento',
            isPrivate: true,
            context: ConsumidorProvider,
            permissions: [
              Permissoes.ATENDENTE,
              Permissoes.GESTOR_DE_FILA,
              Permissoes.ADMINISTRADOR_ATENDIMENTO,
            ],
            permissionValidatorOptionsTransform() {
              return {
                temQualquerPermissao: true,
              };
            },
          },
        ],
      },
      {
        id: '@procon-ms:atendimento',
        path: '/atendimento/:atendimentoId',
        elementPath: 'Atendimento',
        isPrivate: true,
        context: AtendimentoProvedor,
        permissions: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
      },
      {
        id: '@procon-ms:atendimento',
        path: '/atendimento/:triagemId/:atendimentoId',
        elementPath: 'Atendimento',
        isPrivate: true,
        context: AtendimentoProvedor,
        permissions: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
      },
      {
        id: '@procon-ms:-comprovante-atendimento',
        path: '/comprovante-atendimento',
        elementPath: 'ComprovanteAtendimento',
        isPrivate: true,
        permissions: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
      },
      {
        id: '@procon-ms:-comprovante-atendimento',
        path: '/:triagemId/comprovante-atendimento',
        elementPath: 'ComprovanteAtendimento',
        isPrivate: true,
        permissions: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
      },
      {
        id: '@procon-ms:classificacao-de-atendimentos',
        path: '/relatorios/classificacao-de-atendimentos',
        elementPath: 'Relatorios/ClassificacaoAtendimentos',
        isPrivate: true,
        permissions: [
          Permissoes.GESTOR_DE_FILA,
          Permissoes.GESTOR_DE_AUDIENCIA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
        permissionValidatorOptionsTransform() {
          return {
            temQualquerPermissao: true,
          };
        },
      },
    ],
  },
];
