import { Area } from 'models/atendimento/area';
import { Assunto } from 'models/atendimento/assunto';
import {
  Atendimento,
  AtendimentoHistorico,
  DialogoCancelamento,
} from 'models/atendimento/atendimento';
import { ComoContratou } from 'models/atendimento/como-contratou';
import { OrgaoEncaminhadoAtendimento } from 'models/atendimento/orgao-encaminhado';
import { Problema } from 'models/atendimento/problema';
import { TipoAtendimento } from 'models/atendimento/tipo-atendimento';
import { createContext, useContext, useMemo, useState } from 'react';

export interface AtendimentoContextoContextoData {
  atendimento: Atendimento;
  setAtendimento(atendimento: Atendimento): void;
  tiposAtendimento: TipoAtendimento[];
  setTiposAtendimento(tipos: TipoAtendimento[]): void;
  opcoesOrgaoEncaminhado: OrgaoEncaminhadoAtendimento[];
  setOpcoesOrgaoEncaminhado(tipos: OrgaoEncaminhadoAtendimento[]): void;
  opcoesComoContratou: ComoContratou[];
  setOpcoesComoContratou(tipos: ComoContratou[]): void;
  opcoesArea: Area[];
  setOpcoesArea(tipos: Area[]): void;
  opcoesAssunto: Area[];
  setOpcoesAssunto(tipos: Area[]): void;
  opcoesProblema: Problema[];
  setOpcoesProblema(tipos: Problema[]): void;
  dialogoCancelamento: DialogoCancelamento | undefined;
  setDialogoCancelamento(parametros: DialogoCancelamento | undefined): void;
  atendimentoHistorico: AtendimentoHistorico[];
  setAtendimentoHistorico(atendimentoHistorico: AtendimentoHistorico[]): void;
  passoAtualDoAtendimento: number;
  setPassoAtualDoAtendimento(passoAtualDoAtendimento: number): void;
}

export const ListagemAtendimentoContexto =
  createContext<AtendimentoContextoContextoData>(
    {} as AtendimentoContextoContextoData,
  );

export function AtendimentoProvedor({
  children,
}: Record<string, any>): JSX.Element {
  const [atendimento, setAtendimento] = useState<Atendimento>(
    {} as Atendimento,
  );
  const [atendimentoHistorico, setAtendimentoHistorico] = useState<
    AtendimentoHistorico[]
  >([]);

  const [tiposAtendimento, setTiposAtendimento] = useState<TipoAtendimento[]>(
    [],
  );

  const [opcoesComoContratou, setOpcoesComoContratou] = useState<
    ComoContratou[]
  >([]);
  const [opcoesOrgaoEncaminhado, setOpcoesOrgaoEncaminhado] = useState<
    OrgaoEncaminhadoAtendimento[]
  >([]);

  const [opcoesArea, setOpcoesArea] = useState<Area[]>([]);

  const [opcoesAssunto, setOpcoesAssunto] = useState<Assunto[]>([]);

  const [opcoesProblema, setOpcoesProblema] = useState<Problema[]>([]);

  const [dialogoCancelamento, setDialogoCancelamento] = useState<
    DialogoCancelamento | undefined
  >(undefined);

  const [passoAtualDoAtendimento, setPassoAtualDoAtendimento] =
    useState<number>(0);

  const memoTiposAtendimento = useMemo(() => {
    return tiposAtendimento?.filter(
      tipoAtendimento =>
        ((!!tipoAtendimento?.exige_consumidor === !!atendimento?.consumidor ||
          !!tipoAtendimento.exige_consumidor === !!atendimento?.procurador) &&
          !!tipoAtendimento?.exige_fornecedor ===
            (!!atendimento?.fornecedores?.length ||
              !!atendimento?.atendimento_fornecedores?.length)) ||
        !tipoAtendimento.exige_consumidor,
    );
  }, [atendimento, tiposAtendimento]);

  const memoComoContratou = useMemo(() => {
    return opcoesComoContratou;
  }, [opcoesComoContratou]);

  const memoOrgaoEncaminhado = useMemo(() => {
    return opcoesOrgaoEncaminhado;
  }, [opcoesOrgaoEncaminhado]);

  const memoArea = useMemo(() => {
    return opcoesArea;
  }, [opcoesArea]);

  const memoAssunto = useMemo(() => {
    return opcoesAssunto;
  }, [opcoesAssunto]);

  const memoProblema = useMemo(() => {
    return opcoesProblema;
  }, [opcoesProblema]);

  const value = useMemo(
    () => ({
      atendimento,
      setAtendimento,
      tiposAtendimento: memoTiposAtendimento,
      setTiposAtendimento,
      opcoesComoContratou: memoComoContratou,
      opcoesOrgaoEncaminhado: memoOrgaoEncaminhado,
      setOpcoesOrgaoEncaminhado,
      setOpcoesComoContratou,
      opcoesArea: memoArea,
      setOpcoesArea,
      opcoesAssunto: memoAssunto,
      setOpcoesAssunto,
      opcoesProblema: memoProblema,
      setOpcoesProblema,
      dialogoCancelamento,
      setDialogoCancelamento,
      atendimentoHistorico,
      setAtendimentoHistorico,
      passoAtualDoAtendimento,
      setPassoAtualDoAtendimento,
    }),
    [
      atendimento,
      memoTiposAtendimento,
      memoComoContratou,
      memoOrgaoEncaminhado,
      memoArea,
      memoAssunto,
      memoProblema,
      dialogoCancelamento,
      atendimentoHistorico,
      passoAtualDoAtendimento,
    ],
  );

  return (
    <ListagemAtendimentoContexto.Provider value={value}>
      {children}
    </ListagemAtendimentoContexto.Provider>
  );
}

export const useAtendimentoContexto = (): AtendimentoContextoContextoData =>
  useContext(ListagemAtendimentoContexto);
