import { useState, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useCriarMensagemExcecao, useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';
import { ConfiguracoesDeAgenda } from 'models/agenda/configuracoes-de-agenda';
import { tratarRespostaExcecao } from 'utils/tratar-resposta-excecao';
import { UseAdquirirConfiguracoesDeAgendaResponse } from './types';

export function useAdquirirConfiguracoesDeAgenda(): UseAdquirirConfiguracoesDeAgendaResponse {
  const criarMensagem = useGerenciadorDeMensagens();
  const criarMensagemExcecao = useCriarMensagemExcecao();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const adquirirConfiguracoesDeAgenda = useCallback(
    async (unidadeId: number) => {
      try {
        setLoading(true);

        const { data: result } = await servicoBase.get<
          ConfiguracoesDeAgenda[] | [] | null
        >(`/agenda/${unidadeId}/unidadesTodasAgendas`);

        if (result) {
          const configReagendamento = result.filter(
            config => config.eh_para_reagendamento,
          )[0];

          const configDeAgenda = result.filter(
            config => !config.eh_para_reagendamento,
          )[0];

          if (configReagendamento) {
            return (
              {
                ...configDeAgenda,
                reagendamento_segunda: configReagendamento?.expediente_segunda,
                reagendamento_terca: configReagendamento?.expediente_terca,
                reagendamento_quarta: configReagendamento?.expediente_quarta,
                reagendamento_quinta: configReagendamento?.expediente_quinta,
                reagendamento_sexta: configReagendamento?.expediente_sexta,
                reagendamento_sabado: configReagendamento?.expediente_sabado,
                reagendamento_domingo: configReagendamento?.expediente_domingo,
                eh_para_reagendamento: true,
              } || null
            );
          }
          return (
            {
              ...configDeAgenda,
              eh_para_reagendamento: false,
            } || null
          );
        }

        return null;
      } catch (error) {
        const err = error as AxiosError;
        const respostaExcecao = tratarRespostaExcecao(err);

        if (err.response?.status === 412) {
          criarMensagem({
            titulo: 'Aviso',
            texto: respostaExcecao.mensagem,
            tipo: 'atencao',
          });
        } else {
          criarMensagemExcecao(err);
        }

        setErro(error as AxiosError);
      } finally {
        setLoading(false);
      }
      return null;
    },
    [criarMensagem, criarMensagemExcecao],
  );

  return [adquirirConfiguracoesDeAgenda, { loading, erro }];
}
