import {
  ConfiguracoesAgendaContext,
  ConfiguracoesAgendaContextData,
} from 'contexts';
import { useContext } from 'react';
import { useBloc } from './bloc';
import { ConfiguracoesAgendaProviderProps } from './types';

export function ConfiguracoesAgendaProvider({
  children,
}: ConfiguracoesAgendaProviderProps): JSX.Element {
  const { memoValues } = useBloc();

  return (
    <ConfiguracoesAgendaContext.Provider value={memoValues}>
      {children}
    </ConfiguracoesAgendaContext.Provider>
  );
}

export function useCofiguracoesAgendaContext(): ConfiguracoesAgendaContextData {
  const context = useContext(ConfiguracoesAgendaContext);

  return context;
}
