import { AxiosError } from 'axios';
import { RespostaExcecao } from '../models/resposta-excecao';

function obterRespostaExcecaoPadrao(): RespostaExcecao {
  return {
    status: 0,
    tipo: 'erro',
    tipoExcecao: '',
    mensagem: '',
    detalhe: '',
    instancia: '',
  };
}

function isArrayBufferComConteudo(data: any): data is ArrayBuffer {
  return data instanceof ArrayBuffer && data.byteLength > 0;
}

function tratarRespostaArrayBuffer(data: ArrayBuffer): RespostaExcecao {
  const decoder = new TextDecoder('utf-8');
  const dadosDecodificados = decoder.decode(new Uint8Array(data));
  try {
    const respostaJson = JSON.parse(dadosDecodificados);
    return {
      titulo: respostaJson.titulo,
      status: respostaJson.status,
      tipo: respostaJson.tipo,
      tipoExcecao: respostaJson.tipoExcecao,
      mensagem: respostaJson.mensagem,
      detalhe: respostaJson.detalhe,
      instancia: respostaJson.instancia,
    };
  } catch (e) {
    return obterRespostaExcecaoPadrao();
  }
}

function isObjeto(data: any): data is object {
  return data !== null && typeof data === 'object';
}

export function tratarRespostaExcecao(error: AxiosError): RespostaExcecao {
  let respostaExcecao: RespostaExcecao = obterRespostaExcecaoPadrao();

  if (error.response) {
    if (isArrayBufferComConteudo(error.response.data)) {
      respostaExcecao = tratarRespostaArrayBuffer(error.response.data);
    } else if (isObjeto(error.response.data)) {
      respostaExcecao = {
        ...respostaExcecao,
        ...error.response.data,
      };
    }
  }

  return respostaExcecao;
}
