import { useState, useCallback } from 'react';

import { AxiosError } from 'axios';

import { useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';
import { Permissoes } from 'enums';
import { tratarRespostaExcecao } from 'utils/tratar-resposta-excecao';

export type UseAdquirirPermissoesDoUsuario = [
  () => Promise<Permissoes[]>,
  { loading: boolean; erro: AxiosError | null },
];

export function useAdquirirPermissoes(): UseAdquirirPermissoesDoUsuario {
  const criarMensagem = useGerenciadorDeMensagens();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const adquirirPermissoes = useCallback(async () => {
    try {
      setLoading(true);

      const { data: result } = await servicoBase.get<Permissoes[] | null>(
        `/usuarios/permissoes`,
      );

      return result !== null ? result : [];
    } catch (error) {
      const err = error as AxiosError;
      const respostaExcecao = tratarRespostaExcecao(err);

      criarMensagem({
        titulo: 'Erro',
        texto: respostaExcecao.mensagem,
        tipo: 'erro',
      });

      setErro(error as AxiosError);
    } finally {
      setLoading(false);
    }

    return [];
  }, [criarMensagem]);

  return [adquirirPermissoes, { loading, erro }];
}
