import { useState, useCallback } from 'react';

import { AxiosError } from 'axios';

import { useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';
import { Consumidor, RespostaRequisicao } from 'models';

import {
  UseAdquirirConsumidoresResponse,
  AdquirirConsumidoresParams,
} from './types';

export function useAdquirirConsumidores(): UseAdquirirConsumidoresResponse {
  const criarMensagem = useGerenciadorDeMensagens();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const adquirirConsumidores = useCallback(
    async ({ pagina = 1, limite = 10, filtro }: AdquirirConsumidoresParams) => {
      try {
        setLoading(true);

        const { data: result } = await servicoBase.get<RespostaRequisicao<
          Consumidor[]
        > | null>('/consumidores', {
          params: {
            pagina,
            limite,
            filtro,
          },
        });
        return result || null;
      } catch (error) {
        criarMensagem({
          titulo: 'Erro',
          texto:
            'Houve um erro ao adquirir consumidores, por favor tente mais tarde.',
          tipo: 'erro',
        });

        setErro(error as AxiosError);
      } finally {
        setLoading(false);
      }

      return null;
    },
    [criarMensagem],
  );

  return [adquirirConsumidores, { loading, erro }];
}
