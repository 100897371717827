import { createContext } from 'react';

import { Usuario } from 'models';

export interface AuthState {
  usuario?: Usuario;
  token?: string;
  lotacao?: number;
  papelZeroToken?: string;
  aceitouOsTermos?: boolean;
  termosUrl?: string;
}

export type GetTemPermissaoFnOpcoes = {
  temQualquerPermissao?: boolean;
  temTodasAsPermissoes?: boolean;
};

export type GetHasPermissionsFn<T = any> = (
  permissions: T[],
  options?: GetTemPermissaoFnOpcoes,
) => boolean;

export interface AuthContextData {
  token?: string;
  papelZeroToken?: string;
  usuario?: Usuario;
  dispositivoId: string;
  lotacao?: number;
  deslogar(): Promise<void>;
  mudarUsuario(user: any): void;
  mudarToken(newToken: string): void;
  podeAcessar: GetHasPermissionsFn;
  aceitouOsTermos?: boolean;
  termosUrl?: string;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);
