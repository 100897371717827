import ConteudoIndisponivel from 'components/ConteudoIndisponivel';
import { useNavigate } from 'react-router-dom';

export default function Erro() {
  const navigate = useNavigate();

  function onClick() {
    navigate('/app/consulta-atendimento');
  }

  return (
    <div className="dialogo dialogo_medio dialogo_ativo" id="jroeriuoqp24jk">
      <div className="dialogo__container">
        <ConteudoIndisponivel />

        <button
          className="dialogo__botao-fechar"
          type="button"
          onClick={onClick}
          aria-label="Fechar diálogo"
        >
          <i className="far fa-times" aria-label="hidden" />
        </button>
      </div>
    </div>
  );
}
