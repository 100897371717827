import {
  GerenciadorDeMensagem,
  CriarMensagemParams,
} from '@bit/digix.digixui.mensagens-flutuantes';
import { useCallback } from 'react';

export function useGerenciadorDeMensagens(): (
  params: CriarMensagemParams,
) => void {
  const criarMensagem = useCallback((params: CriarMensagemParams) => {
    if (params.tipo === 'erro' || params.tipo === 'atencao') {
      params.tempoDeExibicao = 180000;
    } else {
      params.tempoDeExibicao = 5000;
    }
    GerenciadorDeMensagem.criarMensagem({ ...params });
  }, []);

  return criarMensagem;
}
