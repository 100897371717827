import { useState, useCallback } from 'react';

import { AxiosError } from 'axios';

import { useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';

export type UseAdquirirLotacaoResponse = [
  (cpf: string) => Promise<number | null>,
  { loading: boolean; erro: AxiosError | null },
];

type LotacaoType = {
  id: number;
};

export function useAdquirirLotacao(): UseAdquirirLotacaoResponse {
  const criarMensagem = useGerenciadorDeMensagens();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const adquirirLotacao = useCallback(
    async (cpf: string) => {
      try {
        setLoading(true);

        const { data: result } = await servicoBase.get<LotacaoType | null>(
          `/lotacoes/${cpf}`,
        );

        return result !== null ? result.id : 0;
      } catch (error) {
        criarMensagem({
          titulo: 'Erro',
          texto:
            'Houve um erro ao adquirir a lotação do usuário, por favor tente mais tarde.',
          tipo: 'erro',
        });

        setErro(error as AxiosError);
      } finally {
        setLoading(false);
      }

      return 0;
    },
    [criarMensagem],
  );

  return [adquirirLotacao, { loading, erro }];
}
