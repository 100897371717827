import { Auth0ClientOptions } from '@auth0/auth0-spa-js';

const {
  REACT_APP_AUTH_ZERO_DOMAIN,
  REACT_APP_AUTH_ZERO_CLIENT_ID,
  REACT_APP_AUTH_ZERO_REDIRECT_URI,
  REACT_APP_AUTH_ZERO_AUDIENCE,
  REACT_APP_AUTH_ZERO_SCOPE,
  REACT_APP_AUTH_ZERO_CONNECTION,
  REACT_APP_AUTH_ZERO_USE_REFRESH_TOKEN,
} = process.env;

export const authZeroConfig: Auth0ClientOptions = {
  domain: REACT_APP_AUTH_ZERO_DOMAIN as string,
  clientId: REACT_APP_AUTH_ZERO_CLIENT_ID as string,
  authorizationParams: {
    redirect_uri: REACT_APP_AUTH_ZERO_REDIRECT_URI,
    audience: REACT_APP_AUTH_ZERO_AUDIENCE,
    scope: REACT_APP_AUTH_ZERO_SCOPE,
    connection: REACT_APP_AUTH_ZERO_CONNECTION,
  },
  useRefreshTokens: JSON.parse(REACT_APP_AUTH_ZERO_USE_REFRESH_TOKEN || false),
};
