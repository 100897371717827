// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BrowserOptions } from '@sentry/react';

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENVIRONMENT,
  REACT_APP_SENTRY_TRACE_PROPAGATION_TARGETS,
  REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
} = process.env;
interface SentryConfig {
  sentryDsn: string;
  sentryEnvironment: string;
  sentryTracePropagationTargets: string[];
  sentryTraceSampleRate: number;
  sentryReplaysSessionSampleRate: number;
  sentryReplaysOnErrorSampleRate: number;
}
const sentryConfig: SentryConfig = {
  sentryDsn: REACT_APP_SENTRY_DSN || '',
  sentryEnvironment: REACT_APP_SENTRY_ENVIRONMENT || 'NAO-DEFINIDO',
  sentryTracePropagationTargets: (
    REACT_APP_SENTRY_TRACE_PROPAGATION_TARGETS || '**'
  ).split(','),
  sentryTraceSampleRate: parseFloat(
    REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '1.0',
  ),
  sentryReplaysSessionSampleRate: parseFloat(
    REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || '0.1',
  ),
  sentryReplaysOnErrorSampleRate: parseFloat(
    REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || '1.0',
  ),
};
export const sentryOptions: BrowserOptions = {
  dsn: sentryConfig.sentryDsn,
  environment: sentryConfig.sentryEnvironment,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: sentryConfig.sentryTracePropagationTargets,
  tracesSampleRate: sentryConfig.sentryTraceSampleRate,
  replaysSessionSampleRate: sentryConfig.sentryReplaysSessionSampleRate,
  replaysOnErrorSampleRate: sentryConfig.sentryReplaysOnErrorSampleRate,
};
