import { useState, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';
import { Consumidor } from 'models';
import { useCriarMensagemExcecao } from 'hooks/use-criar-mensagem-excecao';
import { UseAtualizarConsumidorResponse } from './types';

export function useAtualizarConsumidor(): UseAtualizarConsumidorResponse {
  const criarMensagem = useGerenciadorDeMensagens();
  const criarMensagemExcecao = useCriarMensagemExcecao();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const atualizarConsumidor = useCallback(
    async (dado: Consumidor) => {
      const { id: _id, ...restoDado } = dado;
      try {
        setLoading(true);

        const { data: result } = await servicoBase.put<Consumidor | null>(
          `/consumidores/${dado.id}`,
          restoDado,
        );
        const status = result?.status_integracao;

        if (
          status &&
          status.mensagem !== null &&
          status.status_texto === 'PENDENTE'
        ) {
          criarMensagem({
            titulo: 'Atenção',
            texto: status.mensagem || '',
            tipo: 'atencao',
          });
        } else {
          criarMensagem({
            titulo: 'Sucesso',
            texto: `O consumidor ${dado.nome} foi alterado com sucesso.`,
            tipo: 'sucesso',
          });
        }
        return result || null;
      } catch (error) {
        criarMensagemExcecao(error as AxiosError);

        setErro(error as AxiosError);
      } finally {
        setLoading(false);
      }

      return null;
    },
    [criarMensagem, criarMensagemExcecao],
  );

  return [atualizarConsumidor, { loading, erro }];
}
