import { useContext } from 'react';

import { ConsumidorContext, ConsumidorContextData } from 'contexts';

import { useBloc } from './bloc';
import { ConsumidorProviderProps } from './types';

export function ConsumidorProvider({
  children,
}: ConsumidorProviderProps): JSX.Element {
  const { memoValues } = useBloc();

  return (
    <ConsumidorContext.Provider value={memoValues}>
      {children}
    </ConsumidorContext.Provider>
  );
}

export function useConsumidorContext(): ConsumidorContextData {
  const context = useContext(ConsumidorContext);

  return context;
}
