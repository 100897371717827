import { useState, useCallback } from 'react';

import { AxiosError } from 'axios';

import { useCriarMensagemExcecao, useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';
import { Consumidor, CriacaoConsumidorParams } from 'models';

import { UseCriarConsumidorResponse } from './types';

export function useCriarConsumidor(): UseCriarConsumidorResponse {
  const criarMensagem = useGerenciadorDeMensagens();
  const criarMensagemExcecao = useCriarMensagemExcecao();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const criarConsumidor = useCallback(
    async (dado: Consumidor & CriacaoConsumidorParams) => {
      const { id: _id, ...restoDado } = dado;
      try {
        setLoading(true);
        const { data: result } = await servicoBase.post<Consumidor | null>(
          '/consumidores',
          restoDado,
        );
        const status = result?.status_integracao;
        if (
          status &&
          status.mensagem !== null &&
          status.status_texto === 'PENDENTE'
        ) {
          criarMensagem({
            titulo: 'Atenção',
            texto: status.mensagem || '',
            tipo: 'atencao',
          });
        } else {
          criarMensagem({
            titulo: 'Sucesso',
            texto: `O consumidor ${dado.nome} foi adicionado com sucesso.`,
            tipo: 'sucesso',
          });
        }
        return result || null;
      } catch (error) {
        const err = error as AxiosError;
        criarMensagemExcecao(err);

        setErro(err);
      } finally {
        setLoading(false);
      }

      return null;
    },
    [criarMensagem, criarMensagemExcecao],
  );

  return [criarConsumidor, { loading, erro }];
}
