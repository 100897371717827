import React from 'react';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import { sentryOptions } from './config';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

if (sentryOptions.dsn) {
  Sentry.init(sentryOptions);
}

const rootElement = document.getElementById('root') as Element;
const root = createRoot(rootElement);

root.render(
  <>
    <App />
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
