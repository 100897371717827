/* eslint-disable no-plusplus */

import { TiposDePessoaEnum } from 'enums/tipo-de-pessoa';
import { Fornecedor } from 'models';
import { formatToCNPJ, formatToCPF } from 'utils';

export const FORMATO_NUMERO_ATENDIMENTO = '##.##.####.###.#####-#';

export function formatarString(valor: string, padrao: string): string {
  if (!valor || !padrao) return '';

  let i = 0;
  const v = valor.toString();
  const result = padrao.replace(/#/g, _ => v[i++]);
  if (valor.replace(/\s/g, '').length < 9) {
    return result.slice(0, result.indexOf('u'));
  }
  return result;
}

export function getNomeFornecedor(fornecedor: Fornecedor): string {
  const { tipo, nome, nome_social } = fornecedor;

  if (tipo === TiposDePessoaEnum.FISICA) {
    return nome || '';
  }

  if (tipo === TiposDePessoaEnum.JURIDICA && nome_social) {
    return nome_social;
  }

  return 'N/A';
}

export function getDocumentoFornecedor(fornecedor: Fornecedor): string {
  const { tipo, cpf_cnpj } = fornecedor;

  if (tipo === TiposDePessoaEnum.FISICA) {
    return `CPF: ${formatToCPF(cpf_cnpj)}`;
  }

  if (tipo === TiposDePessoaEnum.JURIDICA) {
    return `CNPJ: ${formatToCNPJ(cpf_cnpj)}`;
  }

  return 'N/A';
}
