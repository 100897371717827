import { ReactNode, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks';
import { RouteConfigItem } from 'models';

interface ProtectedPageWrapperProps {
  route: RouteConfigItem;
  children: ReactNode;
  redirectPath: string;
}

export function ProtectedPageWrapper({
  children,
  route,
  redirectPath,
}: ProtectedPageWrapperProps): JSX.Element {
  const { usuario } = useAuth();
  const location = useLocation();

  const memoCanAccess = useMemo(() => {
    return !!route?.isPrivate === !!usuario;
  }, [usuario, route]);

  if (!memoCanAccess) {
    return (
      <Navigate
        to={route?.redirectPath || redirectPath}
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
}
