import { useCallback, useMemo, lazy, Suspense, ComponentType } from 'react';
import { Routes, useLocation, Route } from 'react-router-dom';
import { useAuth } from 'hooks';
import type { RouteConfigItem, RouteProps } from 'models';
import { getNormalizedRoutes } from 'utils';
import { ProtectedPageWrapper, RouteContext } from './components';
import { RouterCreatorProps } from './types';

export function importarRota(elementPath: string) {
  return () => import(`../pages/${elementPath}` as const);
}

function RenderElement({
  route,
  redirectPath,
}: {
  route: RouteConfigItem;
  redirectPath: RouterCreatorProps['redirectPath'];
}) {
  const importComponent = useCallback((elementPath: string) => {
    return lazy<ComponentType<RouteProps>>(importarRota(elementPath));
  }, []);

  const Element = route?.elementPath
    ? importComponent(route.elementPath)
    : route.element;
  const routeProps: RouteProps = {
    name: route?.name,
    routeTitle: route?.routeTitle,
    path: route.path,
  };

  return (
    <Suspense fallback={<></>}>
      <RouteContext route={route}>
        {Element && (
          <ProtectedPageWrapper route={route} redirectPath={redirectPath}>
            <Element {...routeProps} />
          </ProtectedPageWrapper>
        )}
      </RouteContext>
    </Suspense>
  );
}

export function RouterCreator({
  redirectPath,
  routesConfig,
  permissionValidator,
}: RouterCreatorProps): JSX.Element {
  const location = useLocation();
  const { usuario } = useAuth();

  const memoPrivateRoutes = useMemo(() => {
    if (!usuario) {
      return [];
    }
    const normalizedRoutes = getNormalizedRoutes({
      routes: routesConfig,
      removeNoRenderRoutes: true,
      filter: {
        isPrivate: true,
        permissionValidator,
      },
    });

    return normalizedRoutes;
  }, [usuario, routesConfig, permissionValidator]);

  const memoPublicRoutes = useMemo(() => {
    const normalizedRoutes = getNormalizedRoutes({
      routes: routesConfig,
      removeNoRenderRoutes: true,
      filter: {
        isPrivate: false,
        permissionValidator,
      },
    });

    return normalizedRoutes;
  }, [permissionValidator, routesConfig]);

  return (
    <Routes location={location} key={location.pathname}>
      {memoPublicRoutes.map(route => (
        <Route
          key={route.id}
          path={route.path}
          element={<RenderElement redirectPath={redirectPath} route={route} />}
        />
      ))}
      {memoPrivateRoutes.map(route => (
        <Route
          key={route.id}
          path={route.path}
          element={<RenderElement redirectPath={redirectPath} route={route} />}
        />
      ))}
    </Routes>
  );
}
