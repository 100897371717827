import { createContext } from 'react';

import { Opcao } from 'components/Select/types';
import { Consumidor, Fornecedor, Procurador } from 'models';

export interface ConsumidorState {
  incluirFornecedor: boolean;
  consumidorAnonimo: boolean;
  consumidor: Consumidor | null;
  procurador: Procurador | null;
  preferencial: boolean;
  fornecedores: Fornecedor[];
  origemAtendimento: string;
  passoAtual: number;
  origensDeAtendimento: Opcao[];
  loading?: boolean;
  fa?: string;
  senha?: string;
}

export interface ConsumidorContextData extends ConsumidorState {
  mudarConsumidor(consumidor: Consumidor | null): void;
  mudarProcurador(procurador: Consumidor | null): void;
  mudarPreferencial(preferencial: boolean): void;
  mudarPassoAtual(passo: number): void;
  adcionarFornecedores(fornecedores: Fornecedor[]): void;
  mudarIncluirFornecedor(incluirFornecedor: boolean): void;
  mudarConsumidorAnonimo(consumidorAnonimo: boolean): void;
  mudarOrigemAtendimento(origemAtendimento: string): void;
  mudarFa(fa: string): void;
  mudarSenha(senha: string): void;
  voltarParaDadosPadrao(): void;
}

export const ConsumidorContext = createContext<ConsumidorContextData>(
  {} as ConsumidorContextData,
);
