import { AxiosError } from 'axios';
import { tratarRespostaExcecao } from 'utils/tratar-resposta-excecao';
import { useCallback } from 'react';
import { useGerenciadorDeMensagens } from './use-gerenciador-de-mensagens';

export function useCriarMensagemExcecao(): (error: AxiosError) => void {
  const criarMensagem = useGerenciadorDeMensagens();
  const criarMensagemExcecao = useCallback(
    (error: AxiosError) => {
      const err = error as AxiosError;
      const respostaExcecao = tratarRespostaExcecao(err);

      criarMensagem({
        titulo: respostaExcecao.titulo || 'Erro',
        texto:
          respostaExcecao.mensagem || 'Entre em contato com o nosso suporte',
        tipo: respostaExcecao.tipo || 'erro',
      });
    },
    [criarMensagem],
  );

  return criarMensagemExcecao;
}
