import { useState, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useCriarMensagemExcecao, useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';
import { AudienciaCalendario } from 'models';
import { tratarRespostaExcecao } from 'utils/tratar-resposta-excecao';
import { UseAdquirirAudienciaCalendarioResponse } from './types';

export function useAdquirirAudienciaCalendario(): UseAdquirirAudienciaCalendarioResponse {
  const criarMensagem = useGerenciadorDeMensagens();
  const criarMensagemExcecao = useCriarMensagemExcecao();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const useAdquirirAudienciaCalendario = useCallback(
    async (
      idUnidade: number,
      data: string,
      ehReagendamento: boolean,
      ehOnline?: boolean,
    ) => {
      try {
        setLoading(true);

        const { data: result } = await servicoBase.get<
          AudienciaCalendario[] | null
        >(`/audiencia/calendario`, {
          params: {
            data,
            idUnidade,
            ehReagendamento,
            ehOnline,
          },
        });

        if (result) {
          result.map(item => {
            item.data_audiencia = item.data_audiencia.replace('Z', '');
            return item;
          });
        }

        return result || null;
      } catch (error) {
        const err = error as AxiosError;
        const respostaExcecao = tratarRespostaExcecao(err);

        if (err.response?.status === 412) {
          criarMensagem({
            titulo: 'Aviso',
            texto: respostaExcecao.mensagem,
            tipo: 'atencao',
          });
        } else {
          criarMensagemExcecao(err);
        }

        setErro(error as AxiosError);
      } finally {
        setLoading(false);
      }
      return null;
    },
    [criarMensagem, criarMensagemExcecao],
  );

  return [useAdquirirAudienciaCalendario, { loading, erro }];
}
