import { useAuth } from 'hooks';
import { useCallback, useEffect, useMemo } from 'react';

export const useIndecx = (action: string): void => {
  const { usuario } = useAuth();
  const dadosConsumidor = useMemo(() => {
    return {
      key: '6272b1009d743364a923acf2',
      action,
      name: usuario?.name,
      email: usuario?.email,
    };
  }, [usuario, action]);
  const executarPlugin = useCallback(() => {
    try {
      // @ts-ignore
      IndeCX(dadosConsumidor);
    } catch (err) {
      console.info({ err });
    }
  }, [dadosConsumidor]);
  const scriptParaIncluirPesquisaDeSatisfacao = useCallback(() => {
    if (document.getElementById('script-indecx')) {
      executarPlugin();
      return;
    }
    const script = document.createElement('script');
    script.id = 'script-indecx';
    script.src = 'https://indecx-widget.s3.amazonaws.com/widget.js';
    script.type = 'text/javascript';
    script.async = true;
    // eslint-disable-next-line no-multi-assign, func-names
    script.onload = script.onratechange = function () {
      // @ts-ignore
      const estadoDeCarregamento = this.readyState;
      if (
        estadoDeCarregamento &&
        estadoDeCarregamento !== 'complete' &&
        estadoDeCarregamento !== 'loaded'
      ) {
        return;
      }
      executarPlugin();
    };
    document.body.appendChild(script);
  }, [executarPlugin]);
  useEffect(() => {
    scriptParaIncluirPesquisaDeSatisfacao();
  }, [scriptParaIncluirPesquisaDeSatisfacao]);
};
