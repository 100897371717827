import { createContext, useContext, useMemo, useState } from 'react';

import { Triagem } from 'models/fila-atendimento/atendimento';

export interface FilaAtendimentoContextData {
  atendimentos: Triagem[];
  setAtendimentos(atendimentos: Triagem[]): void;
}

export const FilaAtendimentoContext = createContext<FilaAtendimentoContextData>(
  {} as FilaAtendimentoContextData,
);

export function triagemSort(anterior: Triagem, atual: Triagem): number {
  const pegarBloco = (triagem: Triagem) => {
    const idade = triagem?.consumidor?.idade || triagem?.procurador?.idade || 0;
    const { preferencial } = triagem;
    const timeStamp = triagem.data_hora_triagem;
    let bloco = '00';
    bloco = preferencial ? '01' : bloco;
    bloco = idade >= 80 ? '80' : bloco;

    return parseFloat(`${bloco}_${timeStamp}`);
  };

  const bloco_enterior = pegarBloco(anterior);
  const bloco_atual = pegarBloco(atual);

  return bloco_atual - bloco_enterior;
}

export function FilaAtendimentoProvider({
  children,
}: Record<string, any>): JSX.Element {
  const [triagens, setTriagens] = useState<Triagem[]>([]);

  const value = useMemo(
    () => ({
      atendimentos: triagens.sort(triagemSort),
      setAtendimentos: setTriagens,
    }),
    [triagens],
  );

  return (
    <FilaAtendimentoContext.Provider value={value}>
      {children}
    </FilaAtendimentoContext.Provider>
  );
}

export const useFilaAtendimentoContext = (): FilaAtendimentoContextData =>
  useContext(FilaAtendimentoContext);
