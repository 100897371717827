interface ValidadorDePermissoesParams<T> {
  permissoesDeUsuario: T[];
  permissoesParaValidar: T[];
  temQualquerPermissao?: boolean;
  temTodasAsPermissoes?: boolean;
}

export function validadorDePermissoes<T = any>({
  permissoesDeUsuario,
  permissoesParaValidar,
  temQualquerPermissao = false,
  temTodasAsPermissoes = true,
}: ValidadorDePermissoesParams<T>): boolean {
  if (!permissoesDeUsuario.length && !permissoesParaValidar.length) {
    return true;
  }

  if (!permissoesParaValidar.length) {
    return true;
  }

  if (temQualquerPermissao) {
    return permissoesParaValidar.some(permissao =>
      permissoesDeUsuario.includes(permissao),
    );
  }

  if (temTodasAsPermissoes) {
    return permissoesParaValidar.every(permissao =>
      permissoesDeUsuario.includes(permissao),
    );
  }

  return false;
}
