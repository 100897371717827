/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError, AxiosInstance } from 'axios';
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';

export interface ParametrosServiceBase {
  params: any;
}

const servicoBase = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  params: {},
});

const token = localStorage.getItem('token');

servicoBase.defaults.headers.common.Authorization = token
  ? `Bearer ${token}`
  : '';

servicoBase.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    switch (error?.response?.status) {
      case 401:
      case 402:
      case 403: {
        if (window?.deslogar) {
          window.deslogar();
          GerenciadorDeMensagem.criarMensagem({
            titulo: 'Erro',
            tipo: 'erro',
            texto: 'Login expirado, entre novamente.',
          });
          break;
        }

        return Promise.reject(error);
      }
      default:
        return Promise.reject(error);
    }

    return Promise.reject(error);
  },
);

const createAxiosComParametro = (
  params: ParametrosServiceBase,
): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    params: params.params,
  });

  const token = localStorage.getItem('token');
  instance.defaults.headers.common.Authorization = token
    ? `Bearer ${token}`
    : '';

  instance.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      switch (error?.response?.status) {
        case 401:
        case 402:
        case 403: {
          if (window?.deslogar) {
            window.deslogar();
            GerenciadorDeMensagem.criarMensagem({
              titulo: 'Erro',
              tipo: 'erro',
              texto: 'Login expirado, entre novamente.',
            });
            break;
          }

          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

export { servicoBase, createAxiosComParametro };
