import axios from 'axios';

import { Address } from './types';

const viacepApi = axios.create({
  baseURL: 'https://viacep.com.br/ws',
});

export async function getAddressByZipcode(
  zipcode: string,
): Promise<Address | null> {
  try {
    const { data: result } = await viacepApi.get(`/${zipcode}/json`);

    if (result?.erro) {
      return null;
    }

    return result as Address;
  } catch (error) {
    return null;
  }
}
