import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import { authZeroConfig } from 'config';

let auth0Client: Auth0Client | null = null;

export const authZero = async (): Promise<Auth0Client> => {
  if (!auth0Client) {
    auth0Client = await createAuth0Client(authZeroConfig);
  }
  const isAuthenticated = await auth0Client.isAuthenticated();

  if (isAuthenticated) {
    await auth0Client.getTokenSilently();
  }

  (window as any).authZero = auth0Client;

  return auth0Client;
};
