import { useState, useCallback } from 'react';

import { AxiosError } from 'axios';

import { useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';

import {
  OrigemAtendimentoApiType,
  UseAdquirirOrigemAtendimentoResponse,
} from './types';

export function useAdquirirOrigemAtendimento(): UseAdquirirOrigemAtendimentoResponse {
  const criarMensagem = useGerenciadorDeMensagens();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const adquirirOrigemAtendimento = useCallback(async () => {
    try {
      setLoading(true);

      const { data: result } = await servicoBase.get<
        OrigemAtendimentoApiType[] | null
      >(`/origematendimentos`);

      if (result) {
        return result;
      }

      return null;
    } catch (error) {
      criarMensagem({
        titulo: 'Erro',
        texto:
          'Houve um erro ao adquirir origem de atendimento, por favor tente mais tarde.',
        tipo: 'erro',
      });

      setErro(error as AxiosError);
    } finally {
      setLoading(false);
    }

    return null;
  }, [criarMensagem]);

  return [adquirirOrigemAtendimento, { loading, erro }];
}
