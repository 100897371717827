export function formatToCPF(numericCPF: string): string {
  if (!numericCPF) {
    return numericCPF;
  }

  return numericCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export function formatToCNPJ(numericCNPJ: string): string {
  if (!numericCNPJ) {
    return numericCNPJ;
  }

  return String(numericCNPJ)
    ?.replace(/[^\d]+/g, '')
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}

export function formatToCNPJOrCPF(numericvalue: string): string {
  if (!numericvalue) {
    return '';
  }

  if (numericvalue.length > 11) {
    return formatToCNPJ(numericvalue);
  }

  return formatToCPF(numericvalue);
}

export function formatPhoneNumberMask(phoneNumber: string | null): string {
  if (!phoneNumber) {
    return '';
  }

  const newValue = phoneNumber?.replace(/[^\d]/g, '');

  if (newValue && newValue.length > 10) {
    return newValue?.replace(/(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
  }

  return newValue?.replace(/(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
}

export function formatUrlWithProtocol(website: string): string {
  const protocolRegex = /(http(s?)):\/\//gi;
  const hasProtocol = protocolRegex.test(website);

  if (hasProtocol) {
    return website;
  }

  return `https://${website}`;
}

export function formatToCEP(cep: string): string {
  if (!cep) {
    return cep;
  }

  return cep.replace(/(\d{5})(\d{3})/, '$1-$2');
}

export function formataTelefoneMascara(telefone: string): string {
  if (!telefone) return '';

  const numbers = telefone.replace(/[^\d]/g, '');
  const match = numbers.match(/^(\d{2})(\d{4})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return telefone;
}

export function converteStringParaDecimal(valor: string): number | null {
  if (!valor) return null;
  const valorDecimal = parseFloat(valor.replace(/\./g, '').replace(',', '.'));

  return valorDecimal;
}

export function formatarDecimalParaStringMoeda(
  valor: number | null | undefined,
): string {
  if (valor === null || valor === undefined) return '';

  const numeroFormatado = valor.toString().replaceAll('.', ',');

  return numeroFormatado;
}
