const {
  REACT_APP_PAPELZERO_ENDERECO,
  REACT_APP_PAPELZERO_ENDERECO_SEM_AUTH,
  REACT_APP_HORUS_ENDERECO,
  REACT_APP_BUSINESS_INTELIGENCE_ENDERECO,
} = process.env;

interface AmbienteConfig {
  papelZeroEndereco: string;
  papelZeroEnderecoSemAuth: string;
  horusEndereco: string;
  businessInteligenceEndereco: string;
}

export const ambienteConfig: AmbienteConfig = {
  papelZeroEndereco: REACT_APP_PAPELZERO_ENDERECO || '',
  papelZeroEnderecoSemAuth: REACT_APP_PAPELZERO_ENDERECO_SEM_AUTH || '',
  horusEndereco: REACT_APP_HORUS_ENDERECO || '',
  businessInteligenceEndereco: REACT_APP_BUSINESS_INTELIGENCE_ENDERECO || '',
};
