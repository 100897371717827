import { Metric, onCLS, onINP, onLCP } from 'web-vitals';

type ReportHandler = (metric: Metric) => void;

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    onCLS(onPerfEntry);
    onLCP(onPerfEntry);
    onINP(onPerfEntry);
  }
};

export default reportWebVitals;
