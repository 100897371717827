import { useState, useMemo, useEffect, useCallback } from 'react';

import { useLocation } from 'react-router-dom';

import { Opcao } from 'components/Select/types';
import { ConsumidorState, ConsumidorContextData } from 'contexts';
import { Consumidor, Fornecedor, Procurador } from 'models';
import { useAdquirirOrigemAtendimento } from 'services/consumidores';
import { parsedLocalStorage, makeStorageKey } from 'utils';
import { AtendimentoPreferencialEnum } from 'enums';

const dadosPadrao: ConsumidorState = {
  consumidor: null,
  procurador: null,
  preferencial: false,
  fornecedores: [],
  origemAtendimento: '',
  consumidorAnonimo: false,
  incluirFornecedor: true,
  passoAtual: 0,
  origensDeAtendimento: [],
  fa: '',
  senha: '',
};

const StorageKeys = {
  CONSUMIDOR: makeStorageKey('consumidor'),
  PASSO_ATUAL: makeStorageKey('passo-atual'),
  FORNECEDORES: makeStorageKey('fornecedores'),
  ORIGEM_ATENDIMENTO: makeStorageKey('origem-atendimento'),
  CONSUMIDOR_ANONIMO: makeStorageKey('anonimo'),
  INCLUIR_FORNECEDOR: makeStorageKey('incluir-fornecedor'),
  ORIGENS_DE_ATENDIMENTO: makeStorageKey('origens-de-atendimento'),
  FA_ATENDIMENTO: makeStorageKey('fa-atendimento'),
  PREFERENCIAL: makeStorageKey('preferencial'),
  PROCURADOR: makeStorageKey('procurador'),
  SENHA: makeStorageKey('senha'),
};

export function useBloc() {
  const location = useLocation();
  const [adquirirOrigemAtendimento, { loading }] =
    useAdquirirOrigemAtendimento();

  const [data, setData] = useState<ConsumidorState>(() => {
    const fichaDeAtendimento = parsedLocalStorage.getItem<string>(
      StorageKeys.FA_ATENDIMENTO,
    );
    const preferencial = parsedLocalStorage.getItem<boolean>(
      StorageKeys.PREFERENCIAL,
    );
    const origensDeAtendimento = parsedLocalStorage.getItem<Opcao[]>(
      StorageKeys.ORIGENS_DE_ATENDIMENTO,
    );
    const passoAtual = parsedLocalStorage.getItem<number>(
      StorageKeys.PASSO_ATUAL,
    );
    const consumidor = parsedLocalStorage.getItem<Consumidor>(
      StorageKeys.CONSUMIDOR,
    );
    const procurador = parsedLocalStorage.getItem<Procurador>(
      StorageKeys.PROCURADOR,
    );
    const fornecedores = parsedLocalStorage.getItem<Fornecedor[]>(
      StorageKeys.FORNECEDORES,
    );
    const origemAtendimento = parsedLocalStorage.getItem<string>(
      StorageKeys.ORIGEM_ATENDIMENTO,
    );
    const consumidorAnonimo = parsedLocalStorage.getItem<boolean>(
      StorageKeys.CONSUMIDOR_ANONIMO,
    );
    const incluirFornecedor = parsedLocalStorage.getItem<boolean>(
      StorageKeys.INCLUIR_FORNECEDOR,
    );
    const senha = parsedLocalStorage.getItem<string>(StorageKeys.SENHA);
    return {
      fa: fichaDeAtendimento || '',
      consumidor,
      fornecedores: fornecedores || [],
      consumidorAnonimo: !!consumidorAnonimo,
      passoAtual: passoAtual !== null ? passoAtual : 0,
      origemAtendimento: origemAtendimento !== null ? origemAtendimento : '',
      incluirFornecedor: incluirFornecedor !== null ? incluirFornecedor : true,
      origensDeAtendimento: origensDeAtendimento || [],
      procurador,
      senha: senha ?? '',
      preferencial: preferencial !== null ? preferencial : false,
    };
  });

  const mudarPreferencial = useCallback((preferencial: boolean) => {
    setData(oldState => ({
      ...oldState,
      preferencial,
    }));
  }, []);

  const mudarConsumidor = useCallback(
    (consumidor: Consumidor | null) => {
      const consumidorPreferencial =
        consumidor && consumidor?.detalhes_pf ? consumidor.preferencial : false;
      const consumidorFormatado: Consumidor | null = !consumidor
        ? consumidor
        : {
            ...consumidor,
            ...(consumidor?.detalhes_pf
              ? {
                  preferencial: consumidor.preferencial,
                  atendimento_preferencial:
                    consumidor.preferencial === true
                      ? AtendimentoPreferencialEnum.SIM
                      : AtendimentoPreferencialEnum.NAO,
                }
              : {}),
          };
      parsedLocalStorage.setItem(StorageKeys.CONSUMIDOR, consumidorFormatado);
      if (consumidorPreferencial || consumidor?.preferencial) {
        mudarPreferencial(true);
      }

      setData(oldState => ({
        ...oldState,
        consumidor: consumidorFormatado,
      }));
    },
    [mudarPreferencial],
  );

  const mudarSenha = (senha: string) => {
    parsedLocalStorage.setItem(StorageKeys.SENHA, senha);
    setData(oldState => ({
      ...oldState,
      senha,
    }));
  };

  const mudarProcurador = (procurador: Procurador | null) => {
    const procuradorFormatado: Procurador | null = !procurador
      ? procurador
      : {
          ...procurador,
        };
    parsedLocalStorage.setItem(StorageKeys.PROCURADOR, procuradorFormatado);

    setData(oldState => ({
      ...oldState,
      procurador: procuradorFormatado,
    }));
  };

  const adcionarFornecedores = (fornecedores: Fornecedor[]) => {
    parsedLocalStorage.setItem(StorageKeys.FORNECEDORES, fornecedores);
    setData(oldState => ({
      ...oldState,
      fornecedores,
    }));
  };

  const mudarIncluirFornecedor = (incluirFornecedor: boolean) => {
    parsedLocalStorage.setItem(
      StorageKeys.INCLUIR_FORNECEDOR,
      incluirFornecedor,
    );
    setData(oldState => ({
      ...oldState,
      incluirFornecedor,
    }));
  };

  const mudarOrigemAtendimento = (origemAtendimento: string) => {
    parsedLocalStorage.setItem(
      StorageKeys.ORIGEM_ATENDIMENTO,
      origemAtendimento,
    );
    setData(oldState => ({
      ...oldState,
      origemAtendimento,
    }));
  };

  const mudarConsumidorAnonimo = (consumidorAnonimo: boolean) => {
    parsedLocalStorage.setItem(
      StorageKeys.CONSUMIDOR_ANONIMO,
      consumidorAnonimo,
    );
    setData(oldState => ({
      ...oldState,
      consumidorAnonimo,
    }));
  };

  const mudarPassoAtual = (passo: number) => {
    parsedLocalStorage.setItem(StorageKeys.PASSO_ATUAL, passo);
    setData(oldState => ({
      ...oldState,
      passoAtual: passo,
    }));
  };

  const mudarFa = (fa: string) => {
    parsedLocalStorage.setItem(StorageKeys.FA_ATENDIMENTO, fa);
    setData(oldState => ({
      ...oldState,
      fa,
    }));
  };

  const voltarParaDadosPadrao = () => {
    localStorage.removeItem(StorageKeys.CONSUMIDOR);
    localStorage.removeItem(StorageKeys.PROCURADOR);
    localStorage.removeItem(StorageKeys.PASSO_ATUAL);
    localStorage.removeItem(StorageKeys.PREFERENCIAL);
    localStorage.removeItem(StorageKeys.FORNECEDORES);
    localStorage.removeItem(StorageKeys.FA_ATENDIMENTO);
    localStorage.removeItem(StorageKeys.CONSUMIDOR_ANONIMO);
    localStorage.removeItem(StorageKeys.INCLUIR_FORNECEDOR);
    localStorage.removeItem(StorageKeys.ORIGEM_ATENDIMENTO);
    localStorage.removeItem(StorageKeys.ORIGENS_DE_ATENDIMENTO);

    setData(dadosPadrao);
  };

  useEffect(() => {
    async function adquirirOrigensDeAtendimento(): Promise<void> {
      if (
        !data.origensDeAtendimento.length &&
        location.pathname === '/app/pre-atendimento'
      ) {
        const dados = await adquirirOrigemAtendimento();

        if (dados && dados.length) {
          const novoOrigensDeAtendimento = [
            { titulo: 'Selecione', valor: '' },
            ...dados
              .filter(dado => dado.exibir_no_pre_atendimento)
              .reverse()
              .map<Opcao>(({ nome, id }) => ({
                titulo: nome,
                valor: id,
              })),
          ];

          parsedLocalStorage.setItem(
            StorageKeys.ORIGENS_DE_ATENDIMENTO,
            novoOrigensDeAtendimento,
          );

          setData(oldState => ({
            ...oldState,
            origensDeAtendimento: novoOrigensDeAtendimento,
          }));
        }
      }
    }

    adquirirOrigensDeAtendimento();
  }, [adquirirOrigemAtendimento, data.origensDeAtendimento.length, location]);

  const memoValues: ConsumidorContextData = useMemo(
    () => ({
      loading,
      senha: data.senha,
      mudarSenha,
      mudarFa,
      mudarProcurador,
      mudarConsumidor,
      mudarPassoAtual,
      mudarPreferencial,
      adcionarFornecedores,
      voltarParaDadosPadrao,
      mudarConsumidorAnonimo,
      mudarIncluirFornecedor,
      mudarOrigemAtendimento,
      fa: data.fa,
      procurador: data.procurador,
      preferencial: data.preferencial,
      consumidor: data.consumidor,
      passoAtual: data.passoAtual,
      fornecedores: data.fornecedores,
      origemAtendimento: data.origemAtendimento,
      consumidorAnonimo: data.consumidorAnonimo,
      incluirFornecedor: data.incluirFornecedor,
      origensDeAtendimento: data.origensDeAtendimento,
    }),
    [
      loading,
      data.senha,
      data.fa,
      data.procurador,
      data.preferencial,
      data.consumidor,
      data.passoAtual,
      data.fornecedores,
      data.origemAtendimento,
      data.consumidorAnonimo,
      data.incluirFornecedor,
      data.origensDeAtendimento,
      mudarConsumidor,
      mudarPreferencial,
    ],
  );

  return { memoValues };
}
