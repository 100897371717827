import { servicoBase } from '../servico-base';

export async function verificarSeAceitouTermos(cpf: string): Promise<boolean> {
  try {
    const { data: result } = await servicoBase.get<boolean>(
      `/atendimentos/verificartermodeuso`,
      {
        params: {
          login: cpf,
          nomeProduto: process.env.REACT_APP_PRIVACY_POLICY_ENV,
        },
      },
    );

    return result || false;
  } catch {
    return false;
  }
}

export async function getHashedUrlTermos(cpf: string): Promise<string> {
  try {
    const { data: result } = await servicoBase.get<{ url: string }>(
      `/atendimentos/solicitaraceite`,
      {
        params: {
          login: cpf,
          nomeProduto: process.env.REACT_APP_PRIVACY_POLICY_ENV,
          returnUrl: `${window.location.origin}/app/applicativos`,
        },
      },
    );

    return result?.url || '';
  } catch {
    return '';
  }
}
